import * as types from './types';

export const error_happened = (handle, description) => ({
  type: types.ERROR_HAPPENED,
  payload: { handle, description }
});

export const error_dismissed = handle => ({
  type: types.ERROR_DISMISSED,
  payload: handle
});
