import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { delete_user, open_form_for_editing } from '../../../actions/users';
import Table from './Table';

const UserTableContainer = props => {
  const dispatch = useDispatch();
  const users = useSelector(state => state.users.users);
  const on_edit = user => dispatch(open_form_for_editing(user));
  const on_delete = id => dispatch(delete_user(id));

  return <Table users={users} on_edit={on_edit} on_delete={on_delete} />;
};

export default UserTableContainer;
