import * as types from './types';
import { add_loader, remove_loader } from './loader';
import {
  createRestaurant,
  deleteRestaurant,
  getRestaurants,
  updateRestaurant
} from '../api/restaurants';
import { error_happened } from './errors';
import { push } from 'connected-react-router';

export const open_restaurants = () => async dispatch => {
  dispatch(load_restaurants());
  dispatch(push('/restaurants'));
};

export const load_restaurants = () => async (dispatch, getState) => {
  const { token } = getState().auth;
  dispatch(add_loader('loading_restaurants'));
  const result = await getRestaurants(token);
  if (result.status === 200) {
    dispatch(restaurants_loaded(result.data));
  } else {
    dispatch(
      error_happened(
        'rest_fetch_failed',
        'There are problems communicating with the server, Anton broke it again.'
      )
    );
  }
  dispatch(remove_loader('loading_restaurants'));
};

export const restaurants_loaded = restaurants => ({
  type: types.RESTAURANTS_LOADED,
  payload: restaurants
});

export const open_form = () => ({
  type: types.RESTAURANTS_FORM_OPENED
});

export const close_form = () => ({
  type: types.RESTAURANTS_FORM_CLOSED
});

export const form_id_changed = id => ({
  type: types.RESTAURANTS_FORM_ID_SET,
  payload: id
});

export const open_form_for_creation = () => async dispatch => {
  dispatch(clear_form());
  dispatch(open_form());
};

export const clear_form = () => ({
  type: types.RESTAURANTS_CLEAR_FORM
});

export const open_form_for_editing = id => async (dispatch, getState) => {
  const restaurants = getState().restaurants.restaurants;
  const restaurant = restaurants.find(o => o.id === id);
  dispatch(clear_form());
  dispatch(populate_form(restaurant));
  dispatch(open_form());
};

export const populate_form = restaurant => async dispatch => {
  dispatch(name_changed(restaurant.name));
  dispatch(form_id_changed(restaurant.id));
  dispatch(price_range_changed(restaurant.priceRange));
  dispatch(
    location_changed([
      restaurant.restaurantLocation ? restaurant.restaurantLocation.lat : 0,
      restaurant.restaurantLocation ? restaurant.restaurantLocation.lon : 0
    ])
  );
  dispatch(picture_urls_changed(restaurant.images || []));
  dispatch(address_changed(restaurant.address));
  dispatch(promo_line_changed(restaurant.promotionLine));
  dispatch(
    cuisines_changed(new Set(restaurant.cuisines.map(cuisine => cuisine.type)))
  );
  dispatch(open_times_changed(restaurant.openingTimes || []));
  dispatch(close_times_changed(restaurant.closingTimes || []));
};

export const name_changed = name => ({
  type: types.RESTAURANTS_FORM_NAME_CHANGED,
  payload: name
});

export const price_range_changed = price_range => ({
  type: types.RESTAURANTS_FORM_PRICE_RANGE_CHANGED,
  payload: price_range
});

export const location_changed = location => ({
  type: types.RESTAURANTS_FORM_LOCATION_CHANGED,
  payload: location
});

export const picture_urls_changed = picture_urls => ({
  type: types.RESTAURANTS_FORM_PICTURE_URLS_CHANGED,
  payload: picture_urls
});

export const cuisines_changed = cuisines => ({
  type: types.RESTAURANTS_FORM_CUISINES_CHANGED,
  payload: cuisines
});

export const promo_line_changed = promo_line => ({
  type: types.RESTAURANTS_FORM_PROMO_LINE_CHANGED,
  payload: promo_line
});

export const address_changed = address => ({
  type: types.RESTAURANTS_FORM_ADDRESS_CHANGED,
  payload: address
});

export const phone_number_changed = phone_number => ({
  type: types.RESTAURANTS_PHONE_NUMBER_CHANGED,
  payload: phone_number
});

export const open_times_changed = open_times => ({
  type: types.RESTAURANTS_OPENING_TIMES_CHANGED,
  payload: open_times
});

export const close_times_changed = close_times => ({
  type: types.RESTAURANTS_CLOSING_TIMES_CHANGED,
  payload: close_times
});

export const submit_form = () => async (dispatch, getState) => {
  const state = getState();
  const data = extract_restaurant_data_from_state(state.restaurants);
  const id = state.restaurants.form_id;
  const token = state.auth.token;
  dispatch(add_loader('rest_submit'));
  let result;
  if (id === null) {
    result = await createRestaurant(token, data);
  } else {
    result = await updateRestaurant(token, id, data);
  }
  if (result.status === 201 || result.status === 200) {
    dispatch(close_form());
    dispatch(load_restaurants());
  } else {
    dispatch(
      error_happened(
        'err_save_rest',
        'Error saving restaurant. Iliya broke everything again.'
      )
    );
  }
  dispatch(remove_loader('rest_submit'));
};

export const delete_restaurant = id => async (dispatch, getState) => {
  dispatch(add_loader('del_res'));
  const { token } = getState().auth;
  const result = await deleteRestaurant(token, id);
  if (result.status === 200) {
    dispatch(load_restaurants());
  } else {
    dispatch(error_happened('err_del', 'Error deleting restaurant'));
  }
  dispatch(remove_loader('del_res'));
};

const extract_restaurant_data_from_state = state => ({
  name: state.form_name,
  images: state.form_picture_urls,
  cuisines: [...state.form_cuisines].map(cuisine => ({ type: cuisine })),
  priceRange: state.form_price,
  promotionLine: state.form_promo_line,
  restaurantLocation: `POINT(${state.form_location[0]} ${state.form_location[1]})`,
  address: state.form_address,
  phoneNumber: state.form_phone_number,
  openingTimes: state.form_open_times,
  closingTimes: state.form_close_times
});
