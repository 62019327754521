import { top_secret_method } from '../api/utils';
import { push } from 'connected-react-router';
import * as types from './types';
import { add_loader, remove_loader } from './loader';

export const logged_in = auth0 => async dispatch => {
  dispatch(add_loader('auth'));
  const token = await auth0.getTokenSilently();
  try {
    const result = await top_secret_method(token);
    if (result.status === 200) {
      dispatch(push('/dashboard'));
      dispatch({
        type: types.LOGGED_IN,
        payload: token
      });
    }
  } catch (e) {
    await auth0.logout();
  }
  dispatch(remove_loader('auth'));
};
