import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Table from './Table';
import {
  delete_restaurant,
  open_form_for_editing
} from '../../../actions/restaurants';

const RestaurantsTableContainer = () => {
  const restaurants = useSelector(state => state.restaurants.restaurants);
  const dispatch = useDispatch();
  const on_edit = id => dispatch(open_form_for_editing(id));
  const on_delete = id => dispatch(delete_restaurant(id));

  return <Table restaurants={restaurants} on_edit={on_edit} on_delete={on_delete} />;
};

export default RestaurantsTableContainer;
