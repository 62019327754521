import React from 'react';
import { useAuth0 } from './Auth0Wrapper';
import configureStore, { history } from './store';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router'; // react-router v4/v5
import ProtectedRoute from './utils/ProtectedRoute';
import { ConnectedRouter } from 'connected-react-router';

import HomePage from './pages';
import DashboardPage from './pages/dashboard';
import RestaurantsPage from './pages/restaurants';
import UsersPage from './pages/users';
import ProfileImagePage from './pages/ProfileImage';

const store = configureStore();

function App() {
  const { loading } = useAuth0();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path='/' render={() => <HomePage />} />
          <ProtectedRoute
            exact
            path={'/dashboard'}
            render={() => <DashboardPage />}
          />
          <ProtectedRoute
            exact
            path={'/restaurants'}
            render={() => <RestaurantsPage />}
          />
          <ProtectedRoute exact path={'/users'} render={() => <UsersPage />} />
          <ProtectedRoute exact path={'/ProfileImage'} render={() => <ProfileImagePage />} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
