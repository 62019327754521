import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import Actions from './Actions';

const UsersEntry = ({ user, on_edit, on_delete }) => {
  const {
    id,
    email,
    preferences,
    industries,
    userProfile: { name, company, gender, age, description }
  } = user;
  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{company}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell>{industries.map(industry => industry.type).join(', ')}</TableCell>
      <TableCell>
        {preferences.map(preference => preference.type).join(', ')}
      </TableCell>
      <TableCell>{age}</TableCell>
      <TableCell>{gender}</TableCell>
      <TableCell>
        <Actions on_edit={on_edit} on_delete={on_delete} />
      </TableCell>
    </TableRow>
  );
};

export default UsersEntry;
