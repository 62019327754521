import * as types from './types';

export const add_loader = handle => ({
  type: types.ADD_LOADER,
  payload: handle
});

export const remove_loader = handle => ({
  type: types.REMOVE_LOADER,
  payload: handle
});
