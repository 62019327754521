import React, { useState } from 'react';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const Actions = props => {
  const { on_edit, on_delete } = props;
  const [anchor_el, set_anchor_el] = useState(null);

  return (
    <>
      <IconButton onClick={e => set_anchor_el(e.currentTarget)}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchor_el}
        open={anchor_el}
        onClose={() => set_anchor_el(null)}
      >
        <MenuItem onClick={on_edit}>Edit</MenuItem>
        <MenuItem onClick={on_delete}>Delete</MenuItem>
      </Menu>
    </>
  );
};

export default Actions;
