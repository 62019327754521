import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ImageCollection from './ImageCollection';
import {
  set_user_image_state
} from '../../actions/ProfileImage';

const ProfileImageCollectionContainer = () => {
  const profiles = useSelector(state => state.ProfileImage.profiles);
  console.log('########profiles: ', profiles);
  const dispatch = useDispatch();
  const on_approve = id => dispatch(set_user_image_state(id, 'approved'));
  const on_reject = id => dispatch(set_user_image_state(id, 'rejected'));
  // const on_edit = user => {};
  // const on_delete = id => {};

  return <ImageCollection profiles={profiles} on_approve={on_approve} on_reject={on_reject} />;
  // return <div>ProfileImageCollectionContainer: Hello Profile Image</div>;
};

export default ProfileImageCollectionContainer;