import React from 'react';

import { TextField, Button, makeStyles } from '@material-ui/core';
import LocationInput from '../../common/inputs/LocationInput';
import ListInput from '../../common/inputs/ListInput';

import CUISINE_TYPES from '../../../const/cuisines';
import TimesInput from '../../common/inputs/TimesInput';
import ImageUploader from './image_uploader';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2)
  }
}));

const Form = props => {
  const {
    name,
    on_name_changed,
    price_range,
    on_price_range_changed,
    cuisines,
    on_cuisines_changed,
    location,
    on_location_changed,
    promo_line,
    on_promo_line_changed,
    picture_urls,
    on_picture_urls_changed,
    address,
    on_address_changed,
    phone_number,
    on_phone_number_changed,
    open_times,
    on_open_times_changed,
    close_times,
    on_close_times_changed,
    on_submit
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TextField
        value={name}
        onChange={e => on_name_changed(e.target.value)}
        label={'Restaurant name'}
      />
      <TextField
        value={price_range}
        onChange={e => on_price_range_changed(e.target.value)}
        label={'Price range ($$$)'}
      />
      <TextField
        value={promo_line}
        onChange={e => on_promo_line_changed(e.target.value)}
        label={'Promo line'}
      />
      <ImageUploader
        value={picture_urls}
        onSave={url => on_picture_urls_changed(url)}
        label={'Picture URL'}
      />
      <ListInput
        value={cuisines}
        onChange={on_cuisines_changed}
        label={'Cuisines'}
        entries={CUISINE_TYPES}
      />
      <TextField
        value={address}
        onChange={e => on_address_changed(e.target.value)}
        label={'Address'}
      />
      <LocationInput
        value={location}
        onChange={on_location_changed}
        label={'Location'}
      />
      <TextField
        value={phone_number}
        onChange={e => on_phone_number_changed(e.target.value)}
        label={'Phone number'}
      />
      <TimesInput
        value={open_times}
        onChange={on_open_times_changed}
        label={'Opening times'}
      />
      <TimesInput
        value={close_times}
        onChange={on_close_times_changed}
        label={'Closing times'}
      />
      <Button
        className={classes.button}
        onClick={() => {
          on_submit();
        }}
        variant={'contained'}
        color={'primary'}
        size={'large'}
      >
        SUBMIT
      </Button>
    </div>
  );
};

export default Form;
