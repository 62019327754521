const CUISINE_TYPES = [
  'BRITISH',
  'CANADIAN',
  'CARIBBEAN',
  'CHINESE',
  'EGYPTIAN',
  'FILIPINO',
  'FRENCH',
  'GERMAN',
  'GREEK',
  'HUNGARIAN',
  'INDIAN',
  'ITALIAN',
  'JAPANESE',
  'KOREAN',
  'MEDITERRANEAN',
  'MEXICAN',
  'PAKISTANI',
  'PORTUGUESE',
  'RUSSIAN',
  'SPANISH',
  'TAIWANESE',
  'THAI',
  'VEGAN',
  'VIETNAMESE',
  'OTHER'
];

export default CUISINE_TYPES;
