import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  button: {
    margin: theme.spacing(0.5)
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

const ListInput = props => {
  const { entries, value, onChange, label } = props;

  const classes = useStyles();

  const render_entries = () => {
    return entries.map((entry, index) => (
      <Button
        key={index}
        size={'small'}
        variant={value.has(entry) ? 'contained' : 'outlined'}
        color={'primary'}
        className={classes.button}
        onClick={() => {
          const new_entries = new Set(value);
          value.has(entry) ? new_entries.delete(entry) : new_entries.add(entry);
          onChange(new_entries);
        }}
      >
        {entry}
      </Button>
    ));
  };

  return (
    <>
      <Typography variant={'overline'} className={classes.label}>
        {label}
      </Typography>
      <div className={classes.root}>{render_entries()}</div>
    </>
  );
};

export default ListInput;
