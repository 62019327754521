import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Auth0Provider } from './Auth0Wrapper';
import { get_config } from './const/config';
import * as serviceWorker from './serviceWorker';

const CONFIG = get_config();

const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={CONFIG.auth0_domain}
    client_id={CONFIG.auth0_client_id}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    audience={CONFIG.auth0_audience}
    scope={'write:all'}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
