import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from './auth';
import loaderReducer from './loader';
import restaurantsReducer from './restaurants';
import usersReducer from './users';
import ProfileImageReducer from './ProfileImage';

const createRootReducer = history =>
  combineReducers({
    auth: authReducer,
    loader: loaderReducer,
    restaurants: restaurantsReducer,
    users: usersReducer,
    ProfileImage: ProfileImageReducer,
    router: connectRouter(history)
  });
export default createRootReducer;
