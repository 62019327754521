import { get, httpDelete, post, put } from './transport';

export const getUsers = token => get('users', null, token);

export const deleteUser = (token, userId) =>
  httpDelete(`users/${userId}`, null, token);

export const updateUserProfile = (token, userId, profile) =>
  put(`users/${userId}/profile`, profile, token);

export const setUserPreferences = (token, userId, preferences) =>
  post(`users/${userId}/preferences`, preferences, token);

export const setUserIndustries = (token, userId, industries) =>
  post(`users/${userId}/industries`, industries, token);
