import React from 'react';
import { Fab, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { open_form_for_creation } from '../../actions/restaurants';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4)
  }
}));

const AddNewButton = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const on_click = () => dispatch(open_form_for_creation());

  return (
    <Fab
      variant={'extended'}
      color={'primary'}
      onClick={on_click}
      className={classes.root}
    >
      CREATE NEW
    </Fab>
  );
};

export default AddNewButton;
