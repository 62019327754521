// AUTH
export const LOGGED_IN = 'logged_in';

// LOADERS
export const ADD_LOADER = 'add_loader';
export const REMOVE_LOADER = 'remove_loader';

// RESTAURANTS
export const RESTAURANTS_LOADED = 'restaurants_loaded';
export const RESTAURANTS_FORM_OPENED = 'restaurants_form_opened';
export const RESTAURANTS_FORM_CLOSED = 'restaurants_form_closed';
export const RESTAURANTS_FORM_ID_SET = 'restaurants_form_id_set';
export const RESTAURANTS_FORM_NAME_CHANGED = 'restaurants_form_name_changed';
export const RESTAURANTS_FORM_PICTURE_URLS_CHANGED =
  'restaurants_form_picture_urls_changed';
export const RESTAURANTS_FORM_PRICE_RANGE_CHANGED =
  'restaurants_form_price_range_changed';
export const RESTAURANTS_FORM_PROMO_LINE_CHANGED =
  'restaurants_form_promo_line_changed';
export const RESTAURANTS_FORM_LOCATION_CHANGED = 'restaurants_form_location_changed';
export const RESTAURANTS_FORM_CUISINES_CHANGED = 'restaurants_form_cuisines_changed';
export const RESTAURANTS_FORM_ADDRESS_CHANGED = 'restaurants_form_address_changed';
export const RESTAURANTS_CLEAR_FORM = 'restaurants_clear_form';
export const RESTAURANTS_PHONE_NUMBER_CHANGED = 'restaurants_phone_number_changed';
export const RESTAURANTS_OPENING_TIMES_CHANGED = 'restaurants_opening_times_changed';
export const RESTAURANTS_CLOSING_TIMES_CHANGED = 'restaurants_closing_times_changed';

// USERS
export const USERS_LOADED = 'users_loaded';
export const USERS_FORM_OPENED = 'users_form_opened';
export const USERS_FORM_CLOSED = 'users_form_closed';
export const USERS_FORM_USER_SELECTED = 'users_form_user_selected';
export const USERS_FORM_CLEARED = 'users_form_cleared';
export const USERS_FORM_NAME_CHANGED = 'users_form_name_changed';
export const USERS_FORM_COMPANY_CHANGED = 'users_form_company_changed';
export const USERS_FORM_GENDER_CHANGED = 'users_form_gender_changed';
export const USERS_FORM_AGE_CHANGED = 'users_form_age_changed';
export const USERS_FORM_DESCRIPTION_CHANGED = 'users_form_description_changed';
export const USERS_FORM_PREFERENCES_CHANGED = 'users_form_preferences_changed';
export const USERS_FORM_INDUSTRIES_CHANGED = 'users_form_industries_changed';

// PROFILE IMAGE
export const PROFILE_IMAGE_LOADED = 'profile_image_loaded';
// export const USERS_FORM_OPENED = 'users_form_opened';
// export const USERS_FORM_CLOSED = 'users_form_closed';

// ERRORS
export const ERROR_HAPPENED = 'error_happened';
export const ERROR_DISMISSED = 'error_dismissed';
