import {
  getProfileImages,
  setUserImageState
  // setUserIndustries,
  // setUserPreferences,
  // updateUserProfile
} from '../api/profileImage';
import { error_happened } from './errors';
import { add_loader, remove_loader } from './loader';
import { push } from 'connected-react-router';
import * as types from './types';

export const open_ProfileImage = (imageState = 'pending') => async (dispatch, getState) => {
  dispatch(load_ProfileImage(imageState));
  dispatch(push('/ProfileImage'));
};

export const load_ProfileImage = (imageState) => async (dispatch, getState) => {
  const { token } = getState().auth;
  dispatch(add_loader('loading_image_profile'));
  const result = await getProfileImages(imageState, token);
  if (result.status === 200) {
    dispatch(profile_image_loaded(result.data));
    console.log('$$$$$$$$$$$result: ', result);
  } else {
    dispatch(
      error_happened(
        'users_fetch_failed',
        'There are problems communicating with the server, he is very sorry.'
      )
    );
  }
  dispatch(remove_loader('loading_image_profile'));
};

export const profile_image_loaded = profiles => ({
  type: types.PROFILE_IMAGE_LOADED,
  payload: profiles
});

export const set_user_image_state = (id, state) => async (dispatch, getState) => {
  // dispatch(add_loader('set_user_image_state_res'));
  const { token } = getState().auth;
  // console.log('&&&&&&&&id:', id);
  // console.log('&&&&&&&&state:', state);
  const result = await setUserImageState(token, id, {state: state});
  // console.log('&&&&&&&&result:', result);
  if (result.status === 200) {
    // dispatch(load_ProfileImage());
  } else {
    dispatch(error_happened('err_set_user_image_state', 'Error set user image state'));
  }
  // dispatch(remove_loader('set_user_image_state_res'));
};

// export const open_form = () => ({
//   type: types.USERS_FORM_OPENED
// });

// export const close_form = () => ({
//   type: types.USERS_FORM_CLOSED
// });

// export const user_selected = user => ({
//   type: types.USERS_FORM_USER_SELECTED,
//   payload: user
// });

// export const clear_form = () => ({
//   type: types.USERS_FORM_CLEARED
// });

// export const open_form_for_editing = user => async dispatch => {
//   dispatch(populate_form(user));
//   dispatch(user_selected(user));
//   dispatch(open_form());
// };

// export const populate_form = user => async dispatch => {
//   dispatch(name_changed(user.userProfile.name));
//   dispatch(preferences_changed(user.preferences));
//   dispatch(industries_changed(user.industries));
//   dispatch(company_changed(user.userProfile.company));
//   dispatch(gender_changed(user.userProfile.gender));
//   dispatch(age_changed(user.userProfile.age));
//   dispatch(description_changed(user.userProfile.description));
// };

// export const name_changed = name => ({
//   type: types.USERS_FORM_NAME_CHANGED,
//   payload: name
// });

// export const company_changed = company => ({
//   type: types.USERS_FORM_COMPANY_CHANGED,
//   payload: company
// });

// export const gender_changed = gender => ({
//   type: types.USERS_FORM_GENDER_CHANGED,
//   payload: gender
// });

// export const age_changed = age => ({
//   type: types.USERS_FORM_AGE_CHANGED,
//   payload: age
// });

// export const description_changed = description => ({
//   type: types.USERS_FORM_DESCRIPTION_CHANGED,
//   payload: description
// });

// export const preferences_changed = preferences => ({
//   type: types.USERS_FORM_PREFERENCES_CHANGED,
//   payload: preferences
// });

// export const industries_changed = industries => ({
//   type: types.USERS_FORM_INDUSTRIES_CHANGED,
//   payload: industries
// });

// export const submit_form = () => async (dispatch, getState) => {
//   const state = getState();
//   const {
//     form_industries: industries,
//     form_preferences: preferences,
//     form_user_selected: user,
//     form_name: name,
//     form_company: company,
//     form_gender: gender,
//     form_description: description,
//     form_age: age
//   } = state.users;
//   const newUserProfile = {
//     ...user.userProfile,
//     name,
//     company,
//     gender,
//     age,
//     description
//   };
//   const { id } = user;
//   const { token } = state.auth;
//   dispatch(add_loader('rest_submit'));
//   const resultProfile = await updateUserProfile(token, id, newUserProfile);
//   const resultIndustries = await setUserIndustries(
//     token,
//     id,
//     industries.map(i => i.type)
//   );
//   const resultPreferences = await setUserPreferences(
//     token,
//     id,
//     preferences.map(p => p.type)
//   );

//   if (
//     (resultProfile.status === 201 || resultProfile.status === 200) &&
//     (resultIndustries.status === 201 || resultIndustries.status === 200) &&
//     (resultPreferences.status === 201 || resultPreferences.status === 200)
//   ) {
//     dispatch(close_form());
//     dispatch(load_users());
//   } else {
//     dispatch(
//       error_happened(
//         'err_save_rest',
//         'Error saving users. Michael broke everything again.'
//       )
//     );
//   }
//   dispatch(remove_loader('rest_submit'));
// };

// export const delete_user = id => async (dispatch, getState) => {
//   dispatch(add_loader('del_res'));
//   const { token } = getState().auth;
//   const result = await deleteUser(token, id);
//   if (result.status === 200) {
//     dispatch(load_users());
//   } else {
//     dispatch(error_happened('err_del', 'Error deleting user'));
//   }
//   dispatch(remove_loader('del_res'));
// };
