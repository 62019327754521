import { get, post, put } from './transport';

export const getRestaurants = token => get('restaurants', null, token);

export const createRestaurant = (token, restaurantData) =>
  post('restaurants', restaurantData, token);

export const updateRestaurant = (token, restaurantId, updatedRestaurantData) =>
  put(`restaurants/${restaurantId}`, updatedRestaurantData, token);

export const deleteRestaurant = (token, restaurantId) =>
  post(`restaurants/${restaurantId}/delete`, null, token);

export const uploadImage = (token, file) => {
  const formData = new FormData();
  formData.append('file', file);
  return post('restaurants/upload', formData, token);
};
