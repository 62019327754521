import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import FormContainer from './form';
import { useSelector, useDispatch } from 'react-redux';
import { close_form } from '../../actions/restaurants';

const FormModal = () => {
  const form_opened = useSelector(state => state.restaurants.form_opened);
  const dispatch = useDispatch();
  const on_close = () => dispatch(close_form());

  return (
    <Dialog open={form_opened} onClose={on_close}>
      <DialogTitle>Edit restaurant</DialogTitle>
      <DialogContent>
        <FormContainer />
      </DialogContent>
    </Dialog>
  );
};

export default FormModal;
