import React from 'react';
import Scaffold from '../components/scaffold';
import FormModal from '../components/users/FormModal';
import UsersTableContainer from '../components/users/table';

const UsersPage = props => {
  return (
    <Scaffold private_page>
      <UsersTableContainer />
      <FormModal />
    </Scaffold>
  );
};

export default UsersPage;
