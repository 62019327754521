import React from 'react';
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles
} from '@material-ui/core';
import Entry from './Entry';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const RestaurantsTable = props => {
  const { restaurants, on_edit, on_delete } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>NAME</TableCell>
            <TableCell>PICTURE</TableCell>
            <TableCell>CUISINE</TableCell>
            <TableCell>PRICE RANGE</TableCell>
            <TableCell>LOCATION</TableCell>
            <TableCell>ADDRESS</TableCell>
            <TableCell>PROMO LINE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {restaurants.map((restaurant, index) => (
            <Entry
              restaurant={restaurant}
              key={index}
              on_edit={() => on_edit(restaurant.id)}
              on_delete={() => on_delete(restaurant.id)}
            />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default RestaurantsTable;
