import React from 'react';
import Scaffold from '../components/scaffold';
// import FormModal from '../components/users/FormModal';
// import UsersTableContainer from '../components/users/table';
import ProfileImageCollectionContainer from '../components/ProfileImage/ProfileImageCollectionContainer';

const ProfileImagePage = props => {
  return (
    <Scaffold private_page>
      {/* <UsersTableContainer /> */}
      <ProfileImageCollectionContainer />
      {/* <div>Hello Profile Image</div> */}
      {/* <FormModal /> */}
    </Scaffold>
  );
};

export default ProfileImagePage;
