import * as types from '../actions/types';

const INITIAL_STATE = {
  restaurants: [],
  form_opened: false,
  form_id: null,
  form_name: '',
  form_location: [0, 0],
  form_price: '',
  form_cuisines: new Set(),
  form_promo_line: '',
  form_picture_urls: '',
  form_address: '',
  form_phone_number: '',
  form_open_times: [],
  form_close_times: []
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESTAURANTS_LOADED:
      return { ...state, restaurants: action.payload };
    case types.RESTAURANTS_FORM_ID_SET:
      return { ...state, form_id: action.payload };
    case types.RESTAURANTS_FORM_OPENED:
      return { ...state, form_opened: true };
    case types.RESTAURANTS_FORM_CLOSED:
      return { ...state, form_opened: false };
    case types.RESTAURANTS_FORM_CUISINES_CHANGED:
      return { ...state, form_cuisines: action.payload };
    case types.RESTAURANTS_FORM_PROMO_LINE_CHANGED:
      return { ...state, form_promo_line: action.payload };
    case types.RESTAURANTS_FORM_PICTURE_URLS_CHANGED:
      return { ...state, form_picture_urls: action.payload };
    case types.RESTAURANTS_FORM_LOCATION_CHANGED:
      return { ...state, form_location: action.payload };
    case types.RESTAURANTS_FORM_PRICE_RANGE_CHANGED:
      return { ...state, form_price: action.payload };
    case types.RESTAURANTS_FORM_NAME_CHANGED:
      return { ...state, form_name: action.payload };
    case types.RESTAURANTS_PHONE_NUMBER_CHANGED:
      return { ...state, form_phone_number: action.payload };
    case types.RESTAURANTS_OPENING_TIMES_CHANGED:
      return { ...state, form_open_times: action.payload };
    case types.RESTAURANTS_CLOSING_TIMES_CHANGED:
      return { ...state, form_close_times: action.payload };
    case types.RESTAURANTS_FORM_ADDRESS_CHANGED:
      return { ...state, form_address: action.payload };
    case types.RESTAURANTS_CLEAR_FORM:
      return {
        ...state,
        form_location: INITIAL_STATE.form_location,
        form_picture_urls: INITIAL_STATE.form_picture_urls,
        form_name: INITIAL_STATE.form_name,
        form_price: INITIAL_STATE.form_price,
        form_promo_line: INITIAL_STATE.form_promo_line,
        form_address: INITIAL_STATE.form_address,
        form_cuisines: INITIAL_STATE.form_cuisines,
        form_id: INITIAL_STATE.form_id
      };
    default:
      return state;
  }
};

export default reducer;
