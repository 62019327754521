import * as types from '../actions/types';

const INITIAL_STATE = {
  users: [],
  form_opened: false,
  form_user_selected: null,
  form_name: '',
  form_company: '',
  form_gender: '',
  form_age: '',
  form_description: '',
  form_preferences: [],
  form_industries: []
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.USERS_LOADED:
      return { ...state, users: action.payload };
    case types.USERS_FORM_OPENED:
      return { ...state, form_opened: true };
    case types.USERS_FORM_USER_SELECTED:
      return { ...state, form_user_selected: action.payload };
    case types.USERS_FORM_CLOSED:
      return { ...state, form_opened: false };
    case types.USERS_FORM_NAME_CHANGED:
      return { ...state, form_name: action.payload };
    case types.USERS_FORM_DESCRIPTION_CHANGED:
      return { ...state, form_description: action.payload };
    case types.USERS_FORM_COMPANY_CHANGED:
      return { ...state, form_company: action.payload };
    case types.USERS_FORM_GENDER_CHANGED:
      return { ...state, form_gender: action.payload };
    case types.USERS_FORM_AGE_CHANGED:
      return { ...state, form_age: action.payload };
    case types.USERS_FORM_PREFERENCES_CHANGED:
      return { ...state, form_preferences: action.payload };
    case types.USERS_FORM_INDUSTRIES_CHANGED:
      return { ...state, form_industries: action.payload };
    default:
      return state;
  }
};

export default reducer;
