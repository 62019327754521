import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Button } from '@material-ui/core';
import { DropzoneDialog } from 'material-ui-dropzone';
import { uploadImage } from '../../../api/restaurants';

const ImageUploader = ({ value, onSave: onSaveProp }) => {
  const token = useSelector(state => state.auth.token);

  const [open, setOpen] = useState(false);

  const onSave = async files => {
    const result_urls = [];
    for (const file of files) {
      const result = await uploadImage(token, file);
      result_urls.push(result.data);
    }
    onSaveProp(result_urls);
    setOpen(false);
  };

  return (
    <>
      <Typography variant={'h4'}>Current picture(s): </Typography>
      {value ? (
        value.map(v => <img src={v} key={v} alt="" style={{ width: '100px' }} />)
      ) : (
        <Typography variant={'h4'}>No picture set</Typography>
      )}
      <Button onClick={() => setOpen(true)}>Upload a picture</Button>
      <DropzoneDialog
        acceptedFiles={['image/*']}
        filesLimit={5}
        showPreviews={true}
        onSave={onSave}
        open={open}
        dialogTitle={'Choose a picture (best use 1024px by 1024px)'}
      />
    </>
  );
};

export default ImageUploader;
