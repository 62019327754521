import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  makeStyles,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  CircularProgress
} from '@material-ui/core';
import { push } from 'connected-react-router';
import { open_restaurants } from '../actions/restaurants';
import { open_users } from '../actions/users';
import { open_ProfileImage } from '../actions/ProfileImage';
import { useAuth0 } from '../Auth0Wrapper';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {},
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth
  },
  navbar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    marginTop: theme.spacing(8),
    marginLeft: drawerWidth
  }
}));

export default props => {
  const { private_page } = props;
  const classes = useStyles();
  const logged_in = useSelector(state => state.auth.logged_in);
  const is_loading = useSelector(state => state.loader.is_loading);
  const dispatch = useDispatch();
  const { logout } = useAuth0();

  if (!logged_in && private_page) dispatch(push('/'));

  const render_not_logged_in_appbar = () => (
    <AppBar position='static'>
      <Container>
        <Toolbar>
          <Typography variant='h6' className={classes.title}>
            Top Secret YourTable Admin Page 2021
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );

  const render_logged_in_appbar = () => (
    <React.Fragment>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar className={classes.navbar}>
          <Typography variant='h6' noWrap>
            Top Secret YourTable Admin Page 2021
          </Typography>
          <Button onClick={logout} color={'inherit'}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant='permanent'
        classes={{
          paper: classes.drawerPaper
        }}
        anchor='left'
      >
        <div className={classes.toolbar} />
        <Divider />
        <List>
          <ListItem button onClick={() => dispatch(open_users())}>
            <ListItemText primary={'Users'} />
          </ListItem>
          <ListItem button onClick={() => dispatch(open_restaurants())}>
            <ListItemText primary={'Restaurant Promotions'} />
          </ListItem>
          <ListItem button onClick={() => dispatch(open_ProfileImage())}>
            <ListItemText primary={'Profile Images'} />
          </ListItem>
        </List>
      </Drawer>
    </React.Fragment>
  );

  const render_loader = () => (
    <div className={classes.root}>
      <CircularProgress size={'large'} />
    </div>
  );

  if (is_loading) return render_loader();

  return (
    <div className={classes.root}>
      <CssBaseline />
      {logged_in ? render_logged_in_appbar() : render_not_logged_in_appbar()}
      <Container className={`${classes.content} ${props.containerClassName}`}>
        {props.children}
      </Container>
    </div>
  );
};
