import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Form from './form';
import {
  address_changed,
  close_times_changed,
  cuisines_changed,
  location_changed,
  name_changed,
  open_times_changed,
  phone_number_changed,
  picture_urls_changed,
  price_range_changed,
  promo_line_changed,
  submit_form
} from '../../../actions/restaurants';

const RestaurantFormContainer = () => {
  const dispatch = useDispatch();

  const name = useSelector(state => state.restaurants.form_name);
  const on_name_changed = new_name => dispatch(name_changed(new_name));

  const picture_urls = useSelector(state => state.restaurants.form_picture_url);
  const on_picture_urls_changed = new_picture_urls =>
    dispatch(picture_urls_changed(new_picture_urls));

  const location = useSelector(state => state.restaurants.form_location);
  const on_location_changed = new_location =>
    dispatch(location_changed(new_location));

  const promo_line = useSelector(state => state.restaurants.form_promo_line);
  const on_promo_line_changed = new_promo_line =>
    dispatch(promo_line_changed(new_promo_line));

  const price_range = useSelector(state => state.restaurants.form_price);
  const on_price_range_changed = new_price_range =>
    dispatch(price_range_changed(new_price_range));

  const cuisines = useSelector(state => state.restaurants.form_cuisines);
  const on_cuisines_changed = new_cuisines =>
    dispatch(cuisines_changed(new_cuisines));

  const address = useSelector(state => state.restaurants.form_address);
  const on_address_changed = new_address => dispatch(address_changed(new_address));

  const phone_number = useSelector(state => state.restaurants.form_phone_number);
  const on_phone_number_changed = new_phone_number =>
    dispatch(phone_number_changed(new_phone_number));

  const open_times = useSelector(state => state.restaurants.form_open_times);
  const on_open_times_changed = new_open_times =>
    dispatch(open_times_changed(new_open_times));

  const close_times = useSelector(state => state.restaurants.form_close_times);
  const on_close_times_changed = new_close_times =>
    dispatch(close_times_changed(new_close_times));

  const on_submit = () => dispatch(submit_form());

  return (
    <Form
      name={name}
      on_name_changed={on_name_changed}
      picture_urls={picture_urls}
      on_picture_urls_changed={on_picture_urls_changed}
      location={location}
      on_location_changed={on_location_changed}
      promo_line={promo_line}
      on_promo_line_changed={on_promo_line_changed}
      price_range={price_range}
      on_price_range_changed={on_price_range_changed}
      cuisines={cuisines}
      on_cuisines_changed={on_cuisines_changed}
      address={address}
      on_address_changed={on_address_changed}
      phone_number={phone_number}
      on_phone_number_changed={on_phone_number_changed}
      open_times={open_times}
      on_open_times_changed={on_open_times_changed}
      close_times={close_times}
      on_close_times_changed={on_close_times_changed}
      on_submit={on_submit}
    />
  );
};

export default RestaurantFormContainer;
