import React from 'react';
import Scaffold from '../components/scaffold';
import { useDispatch } from 'react-redux';
import { logged_in } from '../actions/auth';
import { useAuth0 } from '../Auth0Wrapper';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  }
}));

export default props => {
  const classes = useStyles();
  const auth0 = useAuth0();
  const dispatch = useDispatch();
  if (auth0.user) {
    dispatch(logged_in(auth0));
  }
  return (
    <Scaffold containerClassName={classes.container}>
      <Button variant={'outlined'} size={'large'} onClick={auth0.loginWithRedirect}>
        LOGIN
      </Button>
    </Scaffold>
  );
};
