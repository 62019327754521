export const INDUSTRIES_TYPES = [
  'ADMINISTRATIVE',
  'ARTS',
  'AUTOMOTIVE',
  'AVIATION',
  'BEAUTY',
  'COMMUNICATIONS',
  'CONSTRUCTION',
  'DESIGN',
  'EDUCATION',
  'ENGINEERING',
  'ENTERTAINMENT',
  'ENTREPRENEURSHIP',
  'ENVIRONMENT',
  'EVENTS',
  'FASHION',
  'FINANCE',
  'FOOD',
  'GOVERNMENT',
  'HEALTH',
  'HOSPITALITY',
  'INSURANCE',
  'LEGAL',
  'MANUFACTURING',
  'MARKETING',
  'MUSIC',
  'NONPROFIT',
  'REALESTATE',
  'RETAIL',
  'SOCIAL',
  'SPORTS',
  'TELECOMMUNICATION',
  'TRANSPORTATION',
  'WELLNESS',
  'OTHER'
];
