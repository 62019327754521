import axios from 'axios';
import { get_config } from '../const/config';

const CONFIG = get_config();

const generate_headers = token => ({
  Authorization: `Bearer ${token}`,
  Accept: 'application/json'
});

const execute = (request, token) => {
  const executable_request = {
    ...request,
    url: `${CONFIG.backend_host}/v1/${request.endpoint}`,
    headers: generate_headers(token)
  };
  if (CONFIG.debug) {
    console.log('Executing this request:');
    console.dir(executable_request);
  }
  return axios(executable_request);
};

export const post = (endpoint, data, token) =>
  execute(
    {
      method: 'POST',
      endpoint,
      data
    },
    token
  );

export const get = (endpoint, data, token) =>
  execute(
    {
      METHOD: 'get',
      endpoint,
      data
    },
    token
  );

export const put = (endpoint, data, token) =>
  execute(
    {
      method: 'PUT',
      endpoint,
      data
    },
    token
  );

export const httpDelete = (endpoint, data, token) =>
  execute(
    {
      method: 'DELETE',
      endpoint,
      data
    },
    token
  );
