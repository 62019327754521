import { Typography, TextField } from '@material-ui/core';
import React from 'react';

const TimesInput = ({ label, value, onChange }) => {
  const onTimeChanged = index => new_time => {
    const new_value = [...value];
    new_value[index] = new_time;
    onChange(new_value);
  };

  return (
    <>
      <Typography variant='h4'>{label}</Typography>
      <TextField
        label='Monday'
        value={value[0]}
        onChange={e => onTimeChanged(0)(e.target.value)}
      />
      <TextField
        label='Tuesday'
        value={value[1]}
        onChange={e => onTimeChanged(1)(e.target.value)}
      />
      <TextField
        label='Wednesday'
        value={value[2]}
        onChange={e => onTimeChanged(2)(e.target.value)}
      />
      <TextField
        label='Thursday'
        value={value[3]}
        onChange={e => onTimeChanged(3)(e.target.value)}
      />
      <TextField
        label='Friday'
        value={value[4]}
        onChange={e => onTimeChanged(4)(e.target.value)}
      />
      <TextField
        label='Saturday'
        value={value[5]}
        onChange={e => onTimeChanged(5)(e.target.value)}
      />
      <TextField
        label='Sunday'
        value={value[6]}
        onChange={e => onTimeChanged(6)(e.target.value)}
      />
    </>
  );
};

export default TimesInput;
