import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  age_changed,
  company_changed,
  description_changed,
  gender_changed,
  industries_changed,
  name_changed,
  preferences_changed,
  submit_form
} from '../../../actions/users';
import Form from './form';

const UserFormContainer = props => {
  const dispatch = useDispatch();
  const name = useSelector(state => state.users.form_name);
  const on_name_changed = newName => dispatch(name_changed(newName));

  const company = useSelector(state => state.users.form_company);
  const on_company_changed = newCompany => dispatch(company_changed(newCompany));

  const gender = useSelector(state => state.users.form_gender);
  const on_gender_changed = newGender => dispatch(gender_changed(newGender));

  const age = useSelector(state => state.users.form_age);
  const on_age_changed = newAge => dispatch(age_changed(newAge));

  const description = useSelector(state => state.users.form_description);
  const on_description_changed = newDescription =>
    dispatch(description_changed(newDescription));

  const preferences = useSelector(state => state.users.form_preferences);
  const on_preferences_changed = newPreferences =>
    dispatch(preferences_changed(newPreferences));

  const industries = useSelector(state => state.users.form_industries);
  const on_industries_changed = newIndustries =>
    dispatch(industries_changed(newIndustries));

  const on_submit = () => dispatch(submit_form());

  return (
    <Form
      name={name}
      on_name_changed={on_name_changed}
      company={company}
      on_company_changed={on_company_changed}
      gender={gender}
      on_gender_changed={on_gender_changed}
      age={age}
      on_age_changed={on_age_changed}
      description={description}
      on_description_changed={on_description_changed}
      preferences={preferences}
      on_preferences_changed={on_preferences_changed}
      industries={industries}
      on_industries_changed={on_industries_changed}
      on_submit={on_submit}
    />
  );
};

export default UserFormContainer;
