import React from 'react';
import {
  makeStyles,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from '@material-ui/core';
import Entry from './Entry';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '90vh',
    overflowX: 'scroll',
    overflowY: 'scroll'
  }
}));

const UsersTable = ({ users, on_edit, on_delete }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Industries</TableCell>
            <TableCell>Preferences</TableCell>
            <TableCell>Age</TableCell>
            <TableCell>Gender</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map(
            (user, index) =>
              user &&
              user.userProfile && (
                <Entry
                  user={user}
                  key={index}
                  on_edit={() => on_edit(user)}
                  on_delete={() => on_delete(user.id)}
                />
              )
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default UsersTable;
