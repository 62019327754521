const PRODUCTION = {
  auth0_client_id: 'HoM1dvsvNHw5ua30Kr8yFkE52ow47W7A',
  auth0_audience: 'https://yourtable.auth.com/',
  auth0_domain: 'dev-d1nv874g.auth0.com',
  backend_host: 'https://yourtable-api-server.herokuapp.com',
  debug: false
};
const DEVELOPMENT = {
  auth0_client_id: 'HoM1dvsvNHw5ua30Kr8yFkE52ow47W7A',
  auth0_audience: 'https://yourtable.auth.com/',
  auth0_domain: 'dev-d1nv874g.auth0.com',
  backend_host: 'https://yourtable-api-server.herokuapp.com',
  // backend_host: 'http://localhost:8080',
  debug: false
};
export const get_config = () =>
  process.env.NODE_ENV === 'production' ? PRODUCTION : DEVELOPMENT;
