import React from 'react';
import { TextField, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

const LocationInput = props => {
  const { value, onChange, label } = props;
  const classes = useStyles();
  return (
    <>
      <Typography variant={'overline'} className={classes.label}>
        {label}
      </Typography>
      <TextField
        value={value[0]}
        onChange={e => onChange([e.target.value, value[1]])}
        label={'Latitude'}
      />
      <TextField
        value={value[1]}
        onChange={e => onChange([value[0], e.target.value])}
        label={'Longitude'}
      />
    </>
  );
};

export default LocationInput;
