import React from 'react';
import {
  Button,
  makeStyles,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  TextField
} from '@material-ui/core';
import { AGE_TYPES } from '../../../const/age';
import CUISINE_TYPES from '../../../const/cuisines';
import { GENDER_TYPES } from '../../../const/gender';
import { INDUSTRIES_TYPES } from '../../../const/industries';
import ListInput from '../../common/inputs/ListInput';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2)
  }
}));

const Form = props => {
  const classes = useStyles();
  const {
    name,
    on_name_changed,
    description,
    on_description_changed,
    age,
    on_age_changed,
    gender,
    on_gender_changed,
    company,
    on_company_changed,
    industries,
    on_industries_changed,
    preferences,
    on_preferences_changed,
    on_submit
  } = props;
  return (
    <div className={classes.root}>
      <TextField
        value={name}
        onChange={e => on_name_changed(e.target.value)}
        label={'Name'}
      />
      <TextField
        value={description}
        onChange={e => on_description_changed(e.target.value)}
        label={'Description'}
      />
      <TextField
        value={company}
        onChange={e => on_company_changed(e.target.value)}
        label={'Company'}
      />
      <ListInput
        value={new Set(industries.map(i => i.type))}
        onChange={i => on_industries_changed([...i].map(_i => ({ type: _i })))}
        label={'Industries'}
        entries={INDUSTRIES_TYPES}
      />
      <ListInput
        value={new Set(preferences.map(p => p.type))}
        onChange={p => on_preferences_changed([...p].map(_p => ({ type: _p })))}
        label={'Preferences'}
        entries={CUISINE_TYPES}
      />
      <FormControl component='fieldset'>
        <FormLabel component='legend'>Gender</FormLabel>
        <RadioGroup
          name='gender'
          value={gender}
          onChange={e => on_gender_changed(e.target.value)}
        >
          {GENDER_TYPES.map(g => (
            <FormControlLabel value={g} control={<Radio />} label={g} />
          ))}
        </RadioGroup>
      </FormControl>
      <FormControl component='fieldset'>
        <FormLabel component='legend'>Age</FormLabel>
        <RadioGroup
          name='age'
          value={age}
          onChange={e => on_age_changed(e.target.value)}
        >
          {AGE_TYPES.map(a => (
            <FormControlLabel value={a} control={<Radio />} label={a} />
          ))}
        </RadioGroup>
      </FormControl>
      <Button
        className={classes.button}
        onClick={on_submit}
        variant={'contained'}
        color={'primary'}
        size={'large'}
      >
        SUBMIT
      </Button>
    </div>
  );
};

export default Form;
