import React from 'react';
// import {
//   makeStyles,
// } from '@material-ui/core';
// import Entry from './Entry';
import styled from 'styled-components';
import ImageCard from './ImageCard';

const BoxContainer = styled.div`
  // display: grid;
  // grid-template-columns: repeat(4, 1fr);
  // grid-template-rows: repeat(2, 1fr);
  // padding-top: 40px;
  // padding-bottom: 30px;
  // grid-gap: 36px;
  // // width : 80%;
  // // width: 300px;
  // // height: 50px;
  // // background-color: red;
  // background-color: #FFFF;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  // justify-content: space-evenly;
  justify-content: flex-start;
  align-items: flex-start;
  // padding-left: 10%;
  // padding-right: 10%;
  padding-top: 40px;

  @media (max-width: 768px)  {
    // grid-template-columns: repeat(4, 1fr);
    // grid-template-rows: repeat(4, 1fr);
    // padding-top: 20px;
    // padding-bottom: 10px;
    // grid-gap: 6px;
  }

  @media (max-width: 425px)  {
    // grid-template-columns: 1fr;
  }
`;

// const useStyles = makeStyles(theme => ({
//   root: {
//     // width: '100%',
//     // height: '90vh',
//     // overflowX: 'scroll',
//     // overflowY: 'scroll'
//   },
//   imageCardContainer: {
//     display: 'flex',
//     flex: 1,
//     flexWrap: 'wrap',
//   },
//   imageCard: {
//     height: 30,
//     width: 20,
//   }
// }));

const ImageCollection = ({ profiles, on_approve, on_reject }) => {
  // const classes = useStyles();
  console.log('%%%%%%%profiles: ', profiles);
  return (
    <BoxContainer>
      { 
        profiles.map(
          (profile, index) => (profile && profile.userImage)
          && (<ImageCard 
                key={`profile-${index}`} 
                profile={profile} 
                on_approve={() => on_approve(profile.id)} 
                on_reject={() => on_reject(profile.id)}
              />))
      }
    </BoxContainer>
  );
};

export default ImageCollection;
