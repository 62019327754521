import * as types from '../actions/types';

const INITIAL_STATE = {
  loaders: [],
  is_loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ADD_LOADER:
      return {
        ...state,
        loaders: [...state.loaders, action.payload],
        is_loading: true
      };
    case types.REMOVE_LOADER:
      const new_loaders = state.loaders.filter(loader => loader !== action.payload);
      const new_is_loading = new_loaders.length > 0;
      return { ...state, loaders: new_loaders, is_loading: new_is_loading };
    default:
      return state;
  }
};
