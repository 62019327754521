import { get, post } from './transport';

export const getProfileImages = (imageState, token) => get(`profile/image/${imageState}`, null, token);

export const setUserImageState = (token, profileId, state) => {
  console.log('!!!!!!userId: userId', profileId);
  console.log('!!!!!!url: userId', `users/profile/${profileId}/user_img_state`);
  return post(`users/profile/${profileId}/user_img_state`, state, token);
}
  
// export const deleteUser = (token, userId) =>
//   httpDelete(`users/${userId}`, null, token);

// export const updateUserProfile = (token, userId, profile) =>
//   put(`users/${userId}/profile`, profile, token);

// export const setUserPreferences = (token, userId, preferences) =>
//   post(`users/${userId}/preferences`, preferences, token);

// export const setUserIndustries = (token, userId, industries) =>
//   post(`users/${userId}/industries`, industries, token);
