import React from 'react';
import Scaffold from '../components/scaffold';
import RestaurantsTableContainer from '../components/restaurants/table';
import FormModal from '../components/restaurants/FormModal';
import AddNewRestaurantButton from '../components/restaurants/AddNewButton';

const RestaurantsPage = () => {
  return (
    <Scaffold private_page>
      <RestaurantsTableContainer />
      <AddNewRestaurantButton />
      <FormModal />
    </Scaffold>
  );
};

export default RestaurantsPage;
