import React, { useState } from 'react';
// import Actions from './Actions';
import styled from 'styled-components';

const imageWidth = '240px';

const theme = {
  blue: {
    default: "#3f51b5",
    hover: "#283593"
  },
  pink: {
    default: "#e91e63",
    hover: "#ad1457"
  }
};

const ImageCardBox = styled.div`
  // height: 50px;
  // width: 10px;
  // width: 332px;
  width: ${imageWidth};
  // height: 50px;
  // padding-top: 10px;
  // padding-bottom: 30px;
  // padding-left: 16px;
  // padding-right: 16px;
  display: flex;
  // flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  // background-color: #FFFF;
  // background-color: orange;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  @media (max-width: 768px)  {
    // width: 100%;
    // padding-left: 4px;
    // padding-right: 4px;
  }

  @media (max-width: 425px)  {
    // width: 100%;
    // padding-left: 2px;
    // padding-right: 0px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex: 1;
  // background-color: red;
  flex-direction: row;
  justify-content: space-evenly;
  // align-items: space-between;
`;

const Button = styled.button`
  background-color: ${(props) => theme[props.theme].default};
  color: white;
  width: 80px;
  // padding: 5px 15px;
  margin: 10px 0px;
  border-radius: 5px;
  outline: 0;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  &:hover {
    background-color: ${(props) => theme[props.theme].hover};
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

Button.defaultProps = {
  theme: "blue"
};

const ButtonToggle = styled(Button)`
  opacity: 0.7;
  ${({ active }) =>
    active &&
    `
    opacity: 1; 
  `}
`;

const ImageCard = ({ profile, on_approve, on_reject }) => {
  const {
    // id,
    userImage,
    userImageState
  } = profile;

  console.log('%%%%%%%userImageState', userImageState);

  // const types = ['approve', 'reject'];

  const [active, setActive] = useState();
  console.log('######active:', active);

  const approve = () => { 
    on_approve();
    setActive('approve');
  }

  const reject = () => {
    on_reject();
    setActive('reject'); 
  }

  return (
    <ImageCardBox>
      <img src={userImage} alt="profile" width={imageWidth} /*height="332"*/ />
      <ButtonRow>
        {/* <Button onClick={on_approve}>Approve</Button>
        <Button onClick={on_reject}>Reject</Button> */}
        <ButtonToggle active={active === 'approve'} onClick={approve}>approve</ButtonToggle>
        <ButtonToggle active={active === 'reject'} onClick={reject}>reject</ButtonToggle>
      {/* {types.map((type) => (
        <ButtonToggle active={active === type} onClick={() => setActive(type)}>
          {type}
        </ButtonToggle>
      ))} */}
      </ButtonRow>
    </ImageCardBox>
  );
};

export default ImageCard;