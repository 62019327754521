import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import Actions from './Actions';

const RestaurantTableEntry = props => {
  const { restaurant, on_edit, on_delete } = props;
  const {
    id,
    name,
    images,
    cuisines,
    priceRange,
    promotionLine,
    restaurantLocation
  } = restaurant;

  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{images}</TableCell>
      <TableCell>{cuisines.map(cuisine => cuisine.type).join(', ')}</TableCell>
      <TableCell>{priceRange}</TableCell>
      <TableCell>
        {restaurantLocation && restaurantLocation.lat},{' '}
        {restaurantLocation && restaurantLocation.lon}
      </TableCell>
      <TableCell>{promotionLine}</TableCell>
      <TableCell>
        <Actions on_edit={on_edit} on_delete={on_delete} />
      </TableCell>
    </TableRow>
  );
};

export default RestaurantTableEntry;
